(function() {
    const WebFont = require('webfontloader');

    WebFont.load({
        google: {
            families: ['Roboto:300,400,700:latin-ext', 'Oswald:300,400,700:latin-ext']
        }
    });
    
    
})();
